/* src/app/shared/components/timer-pop-up-modal/timer-pop-up-modal.component.scss */
.close {
  float: none;
  width: 100%;
  z-index: 9999;
}
.timer-btn {
  width: 100%;
  max-width: 58px;
}
/*# sourceMappingURL=timer-pop-up-modal.component-II3M2WOC.css.map */
