/* src/app/shared/components/timer-buttons/timer-buttons.component.scss */
.start-timer-block-edit {
  opacity: 0.5;
}
span.current-time-string {
  display: inline-block;
  margin: 0 10px;
  line-height: 24px;
  font-weight: 400;
  font-size: 1rem;
}
span {
  display: inline-block;
  margin: 0 10px;
  line-height: 24px;
  font-weight: 400;
  font-size: 1rem;
}
.inline-edit-timer {
  margin-left: 10px;
}
img {
  width: 20px;
  height: auto;
}
.header-timer svg {
  height: 28px;
  width: 28px;
}
.header-timer.start-timer-block-edit {
  opacity: 0.5;
}
.close-edit-new-time svg {
  height: 22px;
  width: 22px;
}
.header-timer-icon {
  align-items: center;
}
.header-timer-icon .new-time-update {
  z-index: 999999 !important;
  width: 80px;
  background: transparent;
  border: none;
  font-size: 1rem;
  color: white;
  border-bottom: 1px solid white;
  outline: none;
}
/*# sourceMappingURL=timer-buttons.component-4YCWEDQZ.css.map */
