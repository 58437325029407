/* src/app/shared/components/documents-upload-in-progress/documents-upload-in-progress.component.scss */
::ng-deep .documents-upload-in-progress-modal .modal-dialog .manual-header {
  padding: 20px;
  background: #F4F7FE;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99;
}
::ng-deep .documents-upload-in-progress-modal .modal-dialog .manual-header .modal-title {
  font-size: 1.5rem;
  line-height: 2.625rem;
  letter-spacing: -0.02em;
  color: #2B3674;
  font-weight: 700;
  display: flex;
  justify-content: center;
  width: 100%;
}
/*# sourceMappingURL=documents-upload-in-progress.component-IEPOMJT5.css.map */
