/* src/app/shared/components/add-update-work-request-modal/add-update-work-request-modal.component.scss */
.foot-btn-wrap {
  display: flex;
  justify-content: space-between;
}
.foot-btn-wrap button.btn {
  width: 50%;
}
.foot-btn-wrap .file-upload-input button.download-document {
  width: 100%;
}
.upload-document-note {
  font-size: 14px;
  margin: 10px 0 0 0;
}
/*# sourceMappingURL=add-update-work-request-modal.component-LURMTQBN.css.map */
