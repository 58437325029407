/* src/app/shared/components/edit-matter-budget-popup/edit-matter-budget-popup.component.scss */
::ng-deep .mat-mdc-slide-toggle.mat-checked .mat-mdc-slide-toggle-bar {
  background-color: rgba(43, 54, 116, 0.54);
}
::ng-deep .mat-mdc-slide-toggle.mat-checked .mat-mdc-slide-toggle-thumb {
  background-color: #2b3674;
}
::ng-deep .matter-active-service-plan-section {
  align-items: center;
}
::ng-deep .matter-active-service-plan-section .service-plan-name {
  margin-bottom: 20px;
}
::ng-deep .row .add-budget-notes {
  margin-bottom: 20px;
  text-decoration: underline #2B3674;
  cursor: pointer;
}
::ng-deep .row .add-budget-notes .add-notes-restricted {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
/*# sourceMappingURL=edit-matter-budget-popup.component-M5FHA3GX.css.map */
