/* src/app/shared/components/reports-help-modal/reports-help-modal.component.scss */
::ng-deep .reports-help-modal .modal-dialog {
  max-width: 800px;
}
::ng-deep .reports-help-modal .modal-dialog .modal-header .close {
  top: 6px;
  text-align: right;
  padding-right: 10px;
}
::ng-deep .reports-help-modal .modal-dialog .modal-header h2 {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
::ng-deep .reports-help-modal .modal-dialog .manual-header {
  padding: 20px;
  background: #F4F7FE;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99;
}
::ng-deep .reports-help-modal .modal-dialog .manual-header .close {
  top: auto;
}
::ng-deep .reports-help-modal .modal-dialog .manual-header .modal-title {
  font-size: 1.5rem;
  line-height: 2.625rem;
  letter-spacing: -0.02em;
  color: #2B3674;
  font-weight: 700;
  width: 100%;
}
::ng-deep .reports-help-modal .modal-dialog .modal-body {
  padding: 20px 0px;
}
::ng-deep .reports-help-modal .modal-dialog .modal-body .sub-text {
  padding-bottom: 0.5rem;
}
::ng-deep .reports-help-modal .special-notes {
  padding-bottom: 0.5rem;
}
::ng-deep .reports-help-modal .special-notes p {
  margin-bottom: 1px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  background-color: #f0f7fb;
  border: solid 1px #3498db;
  border-radius: 6px;
  line-height: 18px;
  overflow: hidden;
  padding: 12px;
}
::ng-deep .reports-help-modal .special-notes.sub-text p {
  background-color: #F3F0FF;
  border-color: #8270DB;
  margin-left: 12px;
  margin-right: 12px;
}
::ng-deep .reports-help-modal .special-notes .notes {
  font-weight: 500;
}
::ng-deep .reports-help-modal .ol-for-embed-link-section,
::ng-deep .reports-help-modal .ol-for-view-in-excel-section,
::ng-deep .reports-help-modal .ol-for-faqs {
  padding-inline-start: 18px;
  margin: 0px;
}
::ng-deep .reports-help-modal .ol-for-embed-link-section p,
::ng-deep .reports-help-modal .ol-for-view-in-excel-section p,
::ng-deep .reports-help-modal .ol-for-faqs p {
  margin-bottom: 0.5rem;
}
::ng-deep .reports-help-modal .ol-for-embed-link-section .list-section,
::ng-deep .reports-help-modal .ol-for-view-in-excel-section .list-section,
::ng-deep .reports-help-modal .ol-for-faqs .list-section {
  padding: 0 1.5rem;
}
::ng-deep .reports-help-modal .ol-for-embed-link-section .list-section ol,
::ng-deep .reports-help-modal .ol-for-view-in-excel-section .list-section ol,
::ng-deep .reports-help-modal .ol-for-faqs .list-section ol {
  padding-inline-start: 18px;
}
::ng-deep .reports-help-modal .ol-for-embed-link-section .list-section .ordered-list-items,
::ng-deep .reports-help-modal .ol-for-view-in-excel-section .list-section .ordered-list-items,
::ng-deep .reports-help-modal .ol-for-faqs .list-section .ordered-list-items {
  list-style: lower-roman;
  list-style-position: outside;
  margin-bottom: 1px;
}
::ng-deep .reports-help-modal .ol-for-embed-link-section .list-section .ordered-list-items p,
::ng-deep .reports-help-modal .ol-for-view-in-excel-section .list-section .ordered-list-items p,
::ng-deep .reports-help-modal .ol-for-faqs .list-section .ordered-list-items p {
  margin-bottom: 1px;
}
::ng-deep .reports-help-modal .ol-for-embed-link-section .steps,
::ng-deep .reports-help-modal .ol-for-view-in-excel-section .steps,
::ng-deep .reports-help-modal .ol-for-faqs .steps {
  font-style: italic;
  font-weight: 500;
}
::ng-deep .reports-help-modal .ol-for-faqs .permission-denied-error-qa .permission-denied-error-img {
  transition: transform 0.2s;
}
::ng-deep .reports-help-modal .ol-for-faqs .permission-denied-error-qa .permission-denied-error-img img {
  width: 100%;
  height: 100%;
}
::ng-deep .reports-help-modal .ol-for-faqs .permission-denied-error-qa .permission-denied-error-img.zoom:hover {
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
::ng-deep .reports-help-modal .ol-for-faqs .permission-denied-error-qa .permission-denied-error-answer {
  margin-top: 1rem;
}
::ng-deep .reports-help-modal .ol-for-faqs .permission-denied-error-qa .permission-denied-error-answer .answer-marker::after {
  content: "-->";
  padding-right: 2px;
  font-weight: bold;
}
::ng-deep .reports-help-modal .card,
::ng-deep .reports-help-modal .accordion-item {
  padding: 0.5rem;
  border-radius: 20px;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}
::ng-deep .reports-help-modal .card .card-header,
::ng-deep .reports-help-modal .card .accordion-header,
::ng-deep .reports-help-modal .accordion-item .card-header,
::ng-deep .reports-help-modal .accordion-item .accordion-header {
  background-color: #F4F7FE;
  border: none;
  padding: 20px 30px;
  border-radius: 20px;
}
::ng-deep .reports-help-modal .card .card-header h4,
::ng-deep .reports-help-modal .card .accordion-header h4,
::ng-deep .reports-help-modal .accordion-item .card-header h4,
::ng-deep .reports-help-modal .accordion-item .accordion-header h4 {
  color: #212112;
  font-size: 1rem;
  line-height: 1.3125rem;
  font-weight: 500;
  margin-bottom: 0;
}
@media only screen and (max-width : 1023px) {
  ::ng-deep .reports-help-modal .card .card-header h4,
  ::ng-deep .reports-help-modal .card .accordion-header h4,
  ::ng-deep .reports-help-modal .accordion-item .card-header h4,
  ::ng-deep .reports-help-modal .accordion-item .accordion-header h4 {
    font-size: 14px;
  }
}
::ng-deep .reports-help-modal .card .card-header .btn-link,
::ng-deep .reports-help-modal .card .card-header .accordion-button,
::ng-deep .reports-help-modal .card .accordion-header .btn-link,
::ng-deep .reports-help-modal .card .accordion-header .accordion-button,
::ng-deep .reports-help-modal .accordion-item .card-header .btn-link,
::ng-deep .reports-help-modal .accordion-item .card-header .accordion-button,
::ng-deep .reports-help-modal .accordion-item .accordion-header .btn-link,
::ng-deep .reports-help-modal .accordion-item .accordion-header .accordion-button {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: 0px;
  color: #212112;
  font-weight: 400;
}
::ng-deep .reports-help-modal .card .card-header .btn-link .arrow-icon,
::ng-deep .reports-help-modal .card .card-header .accordion-button .arrow-icon,
::ng-deep .reports-help-modal .card .accordion-header .btn-link .arrow-icon,
::ng-deep .reports-help-modal .card .accordion-header .accordion-button .arrow-icon,
::ng-deep .reports-help-modal .accordion-item .card-header .btn-link .arrow-icon,
::ng-deep .reports-help-modal .accordion-item .card-header .accordion-button .arrow-icon,
::ng-deep .reports-help-modal .accordion-item .accordion-header .btn-link .arrow-icon,
::ng-deep .reports-help-modal .accordion-item .accordion-header .accordion-button .arrow-icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: #F4F7FE;
}
::ng-deep .reports-help-modal .card .card-header .btn-link .arrow-icon .icon,
::ng-deep .reports-help-modal .card .card-header .accordion-button .arrow-icon .icon,
::ng-deep .reports-help-modal .card .accordion-header .btn-link .arrow-icon .icon,
::ng-deep .reports-help-modal .card .accordion-header .accordion-button .arrow-icon .icon,
::ng-deep .reports-help-modal .accordion-item .card-header .btn-link .arrow-icon .icon,
::ng-deep .reports-help-modal .accordion-item .card-header .accordion-button .arrow-icon .icon,
::ng-deep .reports-help-modal .accordion-item .accordion-header .btn-link .arrow-icon .icon,
::ng-deep .reports-help-modal .accordion-item .accordion-header .accordion-button .arrow-icon .icon {
  display: block;
  line-height: 18px;
}
::ng-deep .reports-help-modal .card .card-header .btn-link[aria-expanded=true] .arrow-icon svg,
::ng-deep .reports-help-modal .card .card-header .accordion-button[aria-expanded=true] .arrow-icon svg,
::ng-deep .reports-help-modal .card .accordion-header .btn-link[aria-expanded=true] .arrow-icon svg,
::ng-deep .reports-help-modal .card .accordion-header .accordion-button[aria-expanded=true] .arrow-icon svg,
::ng-deep .reports-help-modal .accordion-item .card-header .btn-link[aria-expanded=true] .arrow-icon svg,
::ng-deep .reports-help-modal .accordion-item .card-header .accordion-button[aria-expanded=true] .arrow-icon svg,
::ng-deep .reports-help-modal .accordion-item .accordion-header .btn-link[aria-expanded=true] .arrow-icon svg,
::ng-deep .reports-help-modal .accordion-item .accordion-header .accordion-button[aria-expanded=true] .arrow-icon svg {
  transform: rotate(180deg);
}
::ng-deep .reports-help-modal .card .accordion-body,
::ng-deep .reports-help-modal .accordion-item .accordion-body {
  padding: 20px 30px;
}
/*# sourceMappingURL=reports-help-modal.component-5A56Z6HO.css.map */
