import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, Subject } from 'rxjs';
import { APIUrls } from '../enums/apiUrls';
import { BillableHrsListRequest } from '../interfaces/Admin/BillableHours/BillableHrsListRequest';
import { BillableHrsListResponse } from '../interfaces/Admin/BillableHours/BillableHrsListResponse';
import { HJHrsListRequest } from '../interfaces/Admin/HJHours/HJHrsListRequest';
import { HJHrsListResponse } from '../interfaces/Admin/HJHours/HJHrsListResponse';
import {
  MatterClientListForAutomaticTimesheetList,
  MatterClientListResponse,
} from '../interfaces/Admin/HJHours/MatterClientListResponse';
import { MatterClientListResquest } from '../interfaces/Admin/HJHours/MatterClientListResquest';
import { AddEditManualTimeEntryRequest } from '../interfaces/Admin/ManualRequest/AddEditManualTimeEntryRequest';
import { AddManualTimeEntryResponse } from '../interfaces/Admin/ManualRequest/AddManualTimeEntryResponse';
import { AllCategoryResponse } from '../interfaces/Admin/ManualRequest/AllCategoryResponse';
import {
  CheckIfTimesheetIsLockedResponse,
  TimesheetLock,
} from '../interfaces/Admin/ManualRequest/CheckIfTimesheetIsLockedResponse';
import { GetClientByMatterIDResponse } from '../interfaces/Admin/ManualRequest/GetClientByMatterIDResponse';
import { MatterClientByTimeEntryTypeResponse } from '../interfaces/Admin/ManualRequest/MatterClientByTimeEntryTypeResponse';
import { MatterClientByTimeEntryTypeRequest } from '../interfaces/Admin/ManualRequest/MatterClientByTimeEntryTypeResquest';
import { TimeEntryTypeResponse } from '../interfaces/Admin/ManualRequest/TimeEntryTypeResponse';
import { TimesheetManualEntryLogListResponse } from '../interfaces/Admin/ManualRequest/TimesheetManualEntryLogListResponse';
import { MatterByClientIdRequest } from '../interfaces/Admin/Matter/MatterByClientIdRequest';
import { MatterListForClientsResponse } from '../interfaces/Admin/Matter/MatterListForClientsResponse';
import { MattersByClientIdResponse } from '../interfaces/Admin/Matter/MattersByClientIdResponse';
import { SuccessResponse } from '../interfaces/Admin/SuccessResponse';
import { AssignTempHoursToMatterRequest } from '../interfaces/Admin/TempTimeHours/AssignTempHoursToMatterRequest';
import { DashBoardDataResponse } from '../interfaces/Admin/TempTimeHours/DashBoardDataResponse';
import { GetHourDetailsByIdRequest } from '../interfaces/Admin/TempTimeHours/GetHourDetailsByIdRequest';
import { GetHourDetailsbyIdResponse } from '../interfaces/Admin/TempTimeHours/GetHourDetailsbyIdResponse';
import { TempTimeHrsListRequest } from '../interfaces/Admin/TempTimeHours/TempTimeHrsListRequest';
import { TempTimeHrsListResponse } from '../interfaces/Admin/TempTimeHours/TempTimeHrsListResponse';
import { UserListOfLegalTeamResponse } from '../interfaces/Admin/TempTimeHours/UserListOfLegalTeamResponse';
import { EditTimesheetResponse } from '../interfaces/Admin/Timesheet/EditTimesheetResponse';
import { HeadlinePracticeAreaPhaseListResponse } from '../interfaces/Admin/Timesheet/HeadlinePracticeAreaPhaseListResponse';
import {
  IncompleteTimeSheetListRequest,
  IncompleteTimeSheetListResponse,
} from '../interfaces/Admin/Timesheet/IncompleteTimesheetRequestResponse';
import { SplitTimesheetRequest, SplitTimesheetResponse } from '../interfaces/Admin/Timesheet/SplitTimesheet.models';
import { TimeRoundingRequest } from '../interfaces/Admin/Timesheet/TimeRoundingRequest';
import { TimeRoundingResponse } from '../interfaces/Admin/Timesheet/TimeRoundingResponse';
import { TimesheetSummaryRequest } from '../interfaces/Admin/Timesheet/TimesheetSummaryRequest';
import { TimesheetSummaryResponse } from '../interfaces/Admin/Timesheet/TimesheetSummaryResponse';
import { WeeklyTimesheetDetailsByTimesheetIdResponse } from '../interfaces/Admin/Timesheet/WeeklyTimesheetDetailsByTimesheetIdResponse';
import { WeeklyTimesheetListRequest } from '../interfaces/Admin/Timesheet/WeeklyTimesheetListRequest';
import { WeeklyTimesheetListResponse } from '../interfaces/Admin/Timesheet/WeeklyTimesheetListResponse';
import { GetTimeSheetGroupByListResponse } from '../interfaces/Admin/TimesheetReport/GetTimeSheetGroupByListResponse';
import { SaveTimesheetFilterRequest } from '../interfaces/Admin/TimesheetReport/SaveTimesheetFilterRequest';
import { SavedTimesheetFilterDataResponse } from '../interfaces/Admin/TimesheetReport/SavedTimesheetFilterDataResponse';
import { SavedTimesheetFilterResponse } from '../interfaces/Admin/TimesheetReport/SavedTimesheetFilterResponse';
import { TimesheetReportGroupByListResponse } from '../interfaces/Admin/TimesheetReport/TimesheetReportGroupByListResponse';
import {
  GetTimesheetReportListDataGroupByRequest,
  GetTimesheetReportListRequest,
} from '../interfaces/Admin/TimesheetReport/TimesheetReportListRequest';
import { TimesheetReportListResponse } from '../interfaces/Admin/TimesheetReport/TimesheetReportListResponse';
import { TimesheetReportSaveFilterRequest } from '../interfaces/Admin/TimesheetReport/TimesheetReportSaveFilterRequest';
import { ApiResponse } from '../interfaces/api-response.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TimesheetService {
  private nonBillableClientIdSubject = new BehaviorSubject<number>(-1);
  allObservable: Observable<number> = this.nonBillableClientIdSubject.asObservable();

  private updateDashboardDataSubject = new Subject<{ firstLoad: boolean; normalLoad: boolean }>();
  updateDashboardData: Observable<{ firstLoad: boolean; normalLoad: boolean }> =
    this.updateDashboardDataSubject.asObservable();

  private getMatterClientListDataSubject = new Subject<boolean>();
  getMatterClientListData: Observable<boolean> = this.getMatterClientListDataSubject.asObservable();

  private getMatterListDataSubject = new Subject<void>();
  getMatterListData: Observable<void> = this.getMatterListDataSubject.asObservable();

  private clearInlineEditorSubject = new Subject<void>();
  clearInlineEditor: Observable<void> = this.clearInlineEditorSubject.asObservable();

  private startEndTimerSubject = new Subject<{
    isStart: boolean;
    matterClient: MatterClientListForAutomaticTimesheetList;
  }>();
  startEndTimer: Observable<{
    isStart: boolean;
    matterClient: MatterClientListForAutomaticTimesheetList;
  }> = this.startEndTimerSubject.asObservable();

  constructor(private _apiService: ApiService) {}

  clearInlineEditorFn() {
    this.clearInlineEditorSubject.next();
  }

  startEndTimerFn(matterClient: MatterClientListForAutomaticTimesheetList, isStart = false) {
    this.startEndTimerSubject.next({ isStart, matterClient });
  }

  setUpdatedData(data: number) {
    this.nonBillableClientIdSubject.next(data);
  }

  updateNowDashboardData(firstLoad: boolean, normalLoad: boolean) {
    this.updateDashboardDataSubject.next({ firstLoad, normalLoad });
  }

  getNowMatterClientListData(canLoad: boolean) {
    this.getMatterClientListDataSubject.next(canLoad);
  }

  getNowMatterListData() {
    this.getMatterListDataSubject.next();
  }

  getSeconds(decimalTime: number) {
    decimalTime = decimalTime * 60 * 60;
    const hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    const minutes = Math.floor(decimalTime / 60);
    decimalTime = decimalTime - minutes * 60;
    const seconds = Math.round(decimalTime);
    const temp = minutes * 60 + seconds + hours * 60 * 60;
    return temp;
  }

  formatTime(seconds: number, flag: boolean, requireSliced: boolean = false) {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    if (flag) {
      if (s > 30) m = m + 1;
      if (m > 59) {
        h = h + 1;
        m = 0;
      }
      if (h > 23) {
        h = 23;
        m = 59;
      }
    }
    const hourString = h > 9 ? h : '0' + h;
    const hoursMinuteString = h ? '0' + m : '0' + m || '00';
    const minuteString = m > 9 ? m : hoursMinuteString;
    const secondString = s > 9 ? s : '0' + s;
    if (requireSliced) {
      return [hourString, minuteString, secondString].filter(Boolean).join(':').slice(0, 5);
    }
    return [hourString, minuteString, secondString].filter(Boolean).join(':');
  }

  formatDate(date: string) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  formatDateToDDMMYY(date: string) {
    const today: Date = new Date(date);
    const yyyy: number = today.getFullYear();
    const mm: number = today.getMonth() + 1; // Months start at 0!
    const dd: number = today.getDate();
    let ddString: string, mmString: string;

    if (dd < 10) ddString = '0' + dd;
    else ddString = dd.toString();
    if (mm < 10) mmString = '0' + mm;
    else mmString = mm.toString();

    const formattedToday: string = ddString + '/' + mmString + '/' + yyyy;
    return formattedToday;
  }

  formatTimeInHHMM(time: number): string {
    return this.formatTime(this.getSeconds(time || 0), true).slice(0, 5);
  }

  formatTimeInHHMMSS(time: number): string {
    return this.formatTime(this.getSeconds(time || 0), false);
  }

  // dashboard
  getDashboardData(date: string, userID: number): Observable<DashBoardDataResponse> {
    return this._apiService.post<DashBoardDataResponse>(APIUrls.GetDashboardData, {
      currentDate: date,
      userId: userID,
    });
  }

  // common
  getHourDetailsById(hourDetailsByIdReq: GetHourDetailsByIdRequest): Observable<GetHourDetailsbyIdResponse> {
    return this._apiService.post<GetHourDetailsbyIdResponse>(APIUrls.GetHoursDetailsById, {
      ...hourDetailsByIdReq,
    });
  }

  // billable hours
  getBillableHoursList(billableHrsReq: BillableHrsListRequest): Observable<BillableHrsListResponse> {
    return this._apiService.post<BillableHrsListResponse>(APIUrls.GetBillableHrsList, {
      ...billableHrsReq,
    });
  }

  // HJ Hours
  getHJHoursList(hjHrsReq: HJHrsListRequest): Observable<HJHrsListResponse> {
    return this._apiService.post<HJHrsListResponse>(APIUrls.GetHJHrsList, { ...hjHrsReq });
  }

  getMatterClientList(matterClientListReq: MatterClientListResquest): Observable<MatterClientListResponse> {
    return this._apiService.post<MatterClientListResponse>(APIUrls.GetMatterClientList, {
      ...matterClientListReq,
    });
  }

  getSearchMatterClientList(matterClientListReq: MatterClientListResquest): Observable<MatterClientListResponse> {
    return this._apiService.post<MatterClientListResponse>(APIUrls.SearchMatterClientList, {
      ...matterClientListReq,
    });
  }

  // Temp Time HRS
  getUserListForTempTimeHoursFilter(): Observable<UserListOfLegalTeamResponse> {
    return this._apiService.get<UserListOfLegalTeamResponse>(APIUrls.GetUserListOfLegalTeam);
  }

  getAllUserList(): Observable<UserListOfLegalTeamResponse> {
    return this._apiService.get<UserListOfLegalTeamResponse>(APIUrls.GetUserList);
  }

  getTempTimeHoursList(tempTimeHrsReq: TempTimeHrsListRequest): Observable<TempTimeHrsListResponse> {
    return this._apiService.post<TempTimeHrsListResponse>(APIUrls.GetTempTimeHrsList, {
      ...tempTimeHrsReq,
    });
  }

  getMattersByClientId(matterByClientIdReq: MatterByClientIdRequest): Observable<MattersByClientIdResponse> {
    return this._apiService.post<MattersByClientIdResponse>(APIUrls.GetMattersByClientId, {
      ...matterByClientIdReq,
    });
  }

  assignTempTimeHoursToMatter(assignMatterReq: AssignTempHoursToMatterRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.AssignTempHourstoMatter, {
      ...assignMatterReq,
    });
  }

  // Manual Request
  getCategories(): Observable<AllCategoryResponse> {
    return this._apiService.post<AllCategoryResponse>(APIUrls.GetAllCategories, {});
  }

  getTimeEntryTypes(): Observable<TimeEntryTypeResponse> {
    return this._apiService.post<TimeEntryTypeResponse>(APIUrls.GetTimeEntryTypes, {});
  }

  getMatterClientByTimeEntryType(
    matterClientByTimeEntryTypeReq: MatterClientByTimeEntryTypeRequest,
  ): Observable<MatterClientByTimeEntryTypeResponse> {
    return this._apiService.post<MatterClientByTimeEntryTypeResponse>(APIUrls.GetMatterClientByTimeEntryType, {
      ...matterClientByTimeEntryTypeReq,
    });
  }

  getMattersClientsListByTimeEntryTypeAndLegalUser(
    matterClientByTimeEntryTypeReq: MatterClientByTimeEntryTypeRequest,
  ): Observable<MatterClientByTimeEntryTypeResponse> {
    return this._apiService.post<MatterClientByTimeEntryTypeResponse>(APIUrls.GetMattersClientList, {
      ...matterClientByTimeEntryTypeReq,
    });
  }

  getClientByMatterId(matterID: number | null) {
    return this._apiService.get<GetClientByMatterIDResponse>(APIUrls.GetClientByMatterId, { matterId: matterID });
  }

  checkIfTimeSheetLocked(date: string): Observable<CheckIfTimesheetIsLockedResponse> {
    return this._apiService.get<CheckIfTimesheetIsLockedResponse>(`${APIUrls.CheckIfTimesheetLocked}/${date}`);
  }

  addManualTimeEntry(addEditManualTimeEntryReq: AddEditManualTimeEntryRequest): Observable<AddManualTimeEntryResponse> {
    return this._apiService.post<AddManualTimeEntryResponse>(APIUrls.AddEditManualTimeEntry, {
      ...addEditManualTimeEntryReq,
    });
  }

  getTimesheetManualEntryLogList(): Observable<TimesheetManualEntryLogListResponse> {
    return this._apiService.get<TimesheetManualEntryLogListResponse>(APIUrls.GetTimeSheetManualEntryLogList);
  }

  // time sheet listing
  getWeeklyTimesheetList(weeklyTimesheetReq: WeeklyTimesheetListRequest): Observable<WeeklyTimesheetListResponse> {
    return this._apiService.post<WeeklyTimesheetListResponse>(APIUrls.GetWeeklyTimesheetList, {
      ...weeklyTimesheetReq,
    });
  }

  getTimesheetSummaryList(timesheetSummaryReq: TimesheetSummaryRequest): Observable<TimesheetSummaryResponse> {
    return this._apiService.post<TimesheetSummaryResponse>(APIUrls.GetTimesheetSummary, { ...timesheetSummaryReq });
  }

  getIncompleteTimesheetList(
    incompleteTimesheetReq: IncompleteTimeSheetListRequest,
  ): Observable<IncompleteTimeSheetListResponse> {
    return this._apiService.post<IncompleteTimeSheetListResponse>(APIUrls.GetIncompleteTimeSheetList, {
      ...incompleteTimesheetReq,
    });
  }

  getWeeklyTimesheetByTimesheetId(id: number): Observable<WeeklyTimesheetDetailsByTimesheetIdResponse> {
    return this._apiService.get<WeeklyTimesheetDetailsByTimesheetIdResponse>(
      `${APIUrls.GetTimesheetDetailsByTimesheetId}/${id}`,
    );
  }

  // edit timesheet
  editTimesheetEntry(editTimesheetEntry: AddEditManualTimeEntryRequest): Observable<EditTimesheetResponse> {
    return this._apiService.post<EditTimesheetResponse>(APIUrls.AddEditManualTimeEntry, { ...editTimesheetEntry });
  }

  // delete timesheet
  deleteTimesheetEntry(id: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(`${APIUrls.DeleteTimesheetEntry}?id=${id}`, {});
  }

  // lock timesheet
  getLockTimesheet(): Observable<CheckIfTimesheetIsLockedResponse> {
    return this._apiService.get<CheckIfTimesheetIsLockedResponse>(APIUrls.GetLockTimeSheet);
  }

  LockUnlockTimesheet(lockUnlockTimesheetReq: TimesheetLock): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.LockUnLockTimeSheet, { ...lockUnlockTimesheetReq });
  }

  // Timesheet Report
  getTimesheetReport(timesheetreportReq: GetTimesheetReportListRequest): Observable<TimesheetReportListResponse> {
    return this._apiService.post<TimesheetReportListResponse>(APIUrls.GetTimeSheetReport, { ...timesheetreportReq });
  }

  getTimesheetReportGroupBy(
    timesheetreportGrpByReq: GetTimesheetReportListRequest,
  ): Observable<TimesheetReportGroupByListResponse> {
    return this._apiService.post<TimesheetReportGroupByListResponse>(APIUrls.GetTimeSheetReportListForGroupBy, {
      ...timesheetreportGrpByReq,
    });
  }

  getTimesheetReportDataGroupBy(
    timesheetreportGrpByReq: GetTimesheetReportListDataGroupByRequest,
  ): Observable<TimesheetReportGroupByListResponse> {
    return this._apiService.post<TimesheetReportGroupByListResponse>(APIUrls.GetTimeSheetReportDataForGroupBy, {
      ...timesheetreportGrpByReq,
    });
  }

  getTimeSheetGroupByList(): Observable<GetTimeSheetGroupByListResponse> {
    return this._apiService.get<GetTimeSheetGroupByListResponse>(APIUrls.GetTimeSheetGroupByList);
  }

  getTimesheetReportExcel(timesheetreportReq: GetTimesheetReportListRequest) {
    return this._apiService.post(
      APIUrls.ExportTimesheetReportToExcel,
      { ...timesheetreportReq },
      { responseType: 'blob' },
    );
  }

  getTimesheetReportPdf(timesheetreportReq: GetTimesheetReportListRequest) {
    return this._apiService.post(
      APIUrls.ExportTimesheetReportToPdf,
      { ...timesheetreportReq },
      { responseType: 'blob' },
    );
  }

  getTimesheetReportCSV(timesheetreportReq: GetTimesheetReportListRequest) {
    return this._apiService.post(
      APIUrls.ExportTimesheetReportToCSV,
      { ...timesheetreportReq },
      { responseType: 'blob' },
    );
  }

  getTimesheetReportExcelGroupBy(timesheetreportReq: GetTimesheetReportListRequest) {
    return this._apiService.post(
      APIUrls.ExportTimesheetReportGroupedByToExcel,
      { ...timesheetreportReq },
      { responseType: 'blob' },
    );
  }

  getTimesheetReportPdfGroupBy(timesheetreportReq: GetTimesheetReportListRequest) {
    return this._apiService.post(
      APIUrls.ExportTimesheetReportGroupedByToPdf,
      { ...timesheetreportReq },
      { responseType: 'blob' },
    );
  }

  getTimesheetReportCSVGroupBy(timesheetreportReq: GetTimesheetReportListRequest) {
    return this._apiService.post(
      APIUrls.ExportTimesheetReportGroupedByToCSV,
      { ...timesheetreportReq },
      { responseType: 'blob' },
    );
  }

  saveTimesheetReportFilter(
    timesheetReportSaveFilterRequest: TimesheetReportSaveFilterRequest,
  ): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.TimesheetFilter, {
      ...timesheetReportSaveFilterRequest,
    });
  }

  getTimesheetReportFilterList(): Observable<SuccessResponse> {
    return this._apiService.get<SuccessResponse>(APIUrls.GetSavedTimesheetFilterList);
  }

  getTimesheetReportFilterData(): Observable<SuccessResponse> {
    return this._apiService.get<SuccessResponse>(APIUrls.GetSavedTimesheetFilterData);
  }

  //save filter
  getSavedTimesheetFilterList(): Observable<SavedTimesheetFilterResponse> {
    return this._apiService.get<SavedTimesheetFilterResponse>(APIUrls.GetSavedTimesheetFilterList);
  }

  getSavedTimesheetFilterData(id: number): Observable<SavedTimesheetFilterDataResponse> {
    return this._apiService.get<SavedTimesheetFilterDataResponse>(APIUrls.GetSavedTimesheetFilterData, { id: id });
  }

  saveTimesheetFilter(saveTimesheetFilter: SaveTimesheetFilterRequest): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(APIUrls.TimesheetFilter, { ...saveTimesheetFilter });
  }

  checkTimesheetFilterName(name: string, id: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(`${APIUrls.CheckTimesheetFilterName}/${name}?id=${id}`, {});
  }

  deleteFilter(id: number): Observable<SuccessResponse> {
    return this._apiService.post<SuccessResponse>(`${APIUrls.DeleteTimesheetFilter}?id=${id}`, {});
  }

  GetMattersListByClientId(clientId: number[]): Observable<MatterListForClientsResponse> {
    return this._apiService.post<MatterListForClientsResponse>(APIUrls.GetMattersListByClientId, clientId);
  }

  getPracticeAreaHeadPhaseList(matterID: number): Observable<HeadlinePracticeAreaPhaseListResponse> {
    return this._apiService.get<HeadlinePracticeAreaPhaseListResponse>(APIUrls.GetPracticeAreaHeadPhaseList, {
      id: matterID,
    });
  }

  getIsTimeRounded(timeRoundedRequest: TimeRoundingRequest): Observable<TimeRoundingResponse> {
    return this._apiService.post<TimeRoundingResponse>(APIUrls.GetIsTimeRounded, {
      ...timeRoundedRequest,
    });
  }

  splitTimesheet(splitRequest: SplitTimesheetRequest): Observable<ApiResponse<SplitTimesheetResponse>> {
    return this._apiService.post<ApiResponse<SplitTimesheetResponse>>('Timesheet/split', splitRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        return of({
          status: false,
          message: error.message,
          statusCode: error.status,
        } as ApiResponse<SplitTimesheetResponse>);
      }),
    );
  }
}
