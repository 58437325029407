/* src/app/shared/components/long-running-timer-modal/long-running-timer-modal.component.scss */
::ng-deep .alert-long-running-timer .modal-dialog {
  max-width: 650px !important;
}
::ng-deep .alert-long-running-timer .modal-header .modal-title {
  width: 100%;
  text-align: center;
}
::ng-deep .alert-long-running-timer .modal-body .mb {
  margin-bottom: 10px;
}
/*# sourceMappingURL=long-running-timer-modal.component-TJO24YJN.css.map */
