/* src/app/shared/components/delete-data-modal/delete-data-modal.component.scss */
::ng-deep .delete-data-modal .modal-dialog .modal-header .close {
  top: 6px;
  text-align: right;
  padding-right: 10px;
}
::ng-deep .delete-data-modal .modal-dialog .modal-header h2 {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
::ng-deep .delete-data-modal .modal-dialog .manual-header {
  padding: 20px;
  background: #F4F7FE;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99;
}
::ng-deep .delete-data-modal .modal-dialog .manual-header .close {
  top: auto;
}
::ng-deep .delete-data-modal .modal-dialog .manual-header .modal-title {
  font-size: 1.5rem;
  line-height: 2.625rem;
  letter-spacing: -0.02em;
  color: #2B3674;
  font-weight: 700;
  display: flex;
  justify-content: center;
  width: 100%;
}
::ng-deep .edit-timesheet-delete .modal-body {
  padding: 40px 20px 20px !important;
}
/*# sourceMappingURL=delete-data-modal.component-VKVYGFLK.css.map */
