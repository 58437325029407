/* src/app/shared/components/otp-verification/otp-verification.component.scss */
.otp-input-wrapper {
  display: flex;
}
.waiting-time-with-resend-option {
  padding-top: 5px;
}
.waiting-time-with-resend-option > p {
  margin: 0;
  padding-bottom: 2px;
}
/*# sourceMappingURL=otp-verification.component-4VWOGC3Y.css.map */
