/* src/app/shared/components/vertical-navigation/vertical-navigation.component.scss */
.asm-menu-anchor__icon.menu-icon {
  margin-right: 0;
}
.asm-menu-anchor__label {
  margin-left: 15px;
}
mat-nav-list::ng-deep .asm-menu-anchor__child {
  padding-left: 30px !important;
  margin-left: 0;
}
mat-nav-list::ng-deep .asm-menu-anchor__child .asm-menu-anchor__label {
  padding-left: 20px;
}
mat-nav-list::ng-deep .asm-menu-anchor__child .asm-menu-anchor__icon {
  opacity: 1;
  margin-left: 3px;
}
mat-nav-list::ng-deep a span {
  height: 24px;
}
.children a {
  border-left: 0;
}
.children .asm-menu-anchor {
  background-color: #DAE1F9;
}
.children .asm-menu-anchor__child {
  background-color: #DAE1F9;
}
.children .asm-menu-anchor__child:hover {
  background-color: #F4F7FE !important;
}
.children .asm-menu-anchor__child.active-list-item {
  background-color: #F4F7FE;
}
/*# sourceMappingURL=vertical-navigation.component-IWRVRI4C.css.map */
