import * as i0 from '@angular/core';
import { Directive, Optional, Input, HostListener, NgModule } from '@angular/core';
import * as i1 from '@angular/forms';
class NgxTrimDirective {
  constructor(elementRef, ngControl) {
    this.elementRef = elementRef;
    this.ngControl = ngControl;
    this.trimOnWriteValue = true;
  }
  set trim(trimOption) {
    if (trimOption !== '' && trimOption !== 'blur' && trimOption !== false) {
      console.warn(`Note: The value ${JSON.stringify(trimOption)} is not assignable to the trim attribute.
        Only blank string (""), "blur" or false is allowed.`);
      this._trim = false;
      return;
    }
    this._trim = trimOption;
    const elem = this.elementRef.nativeElement;
    const eleValue = elem.value;
    if (trimOption !== false && eleValue !== eleValue.trim()) {
      // initially trim the value if needed
      NgxTrimDirective.dispatchEvent(elem, 'blur');
    }
  }
  get trim() {
    return this._trim;
  }
  static getCaret(el) {
    return {
      start: el.selectionStart,
      end: el.selectionEnd
    };
  }
  static setCaret(el, start, end) {
    el.selectionStart = start;
    el.selectionEnd = end;
    el.focus();
  }
  static dispatchEvent(el, eventType) {
    const event = document.createEvent('Event');
    event.initEvent(eventType, false, false);
    el.dispatchEvent(event);
  }
  static trimValue(el, value) {
    el.value = value.trim();
    NgxTrimDirective.dispatchEvent(el, 'input');
  }
  ngOnInit() {
    if (!this.ngControl) {
      console.warn('Note: The trim directive should be used with one of ngModel, formControl or formControlName directives.');
      return;
    }
    this._valueAccessor = this.ngControl.valueAccessor;
    this._writeValue = this._valueAccessor.writeValue;
    this._valueAccessor.writeValue = value => {
      const _value = this.trim === false || !value || 'function' !== typeof value.trim || !this.trimOnWriteValue ? value : value.trim();
      if (this._writeValue) {
        this._writeValue.call(this._valueAccessor, _value);
      }
      if (value !== _value) {
        if (this._valueAccessor['onChange']) {
          this._valueAccessor['onChange'](_value);
        }
        if (this._valueAccessor['onTouched']) {
          this._valueAccessor['onTouched']();
        }
      }
    };
  }
  ngOnDestroy() {
    if (this._valueAccessor && this._writeValue) {
      this._valueAccessor.writeValue = this._writeValue;
    }
  }
  onBlur(el, value) {
    if (this.trim === false) {
      return;
    }
    if ((this.trim === '' || 'blur' === this.trim) && 'function' === typeof value.trim && value.trim() !== value) {
      NgxTrimDirective.trimValue(el, value);
      NgxTrimDirective.dispatchEvent(el, 'blur'); // in case updateOn is set to blur
    }
  }
  onInput(el, value) {
    if (this.trim === false) {
      return;
    }
    if (this.trim === '' && 'function' === typeof value.trim && value.trim() !== value) {
      let {
        start,
        end
      } = NgxTrimDirective.getCaret(el);
      if (value[0] === ' ' && start === 1 && end === 1) {
        start = 0;
        end = 0;
      }
      NgxTrimDirective.trimValue(el, value);
      NgxTrimDirective.setCaret(el, start, end);
    }
  }
}
/** @nocollapse */ /** @nocollapse */
NgxTrimDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.2.3",
  ngImport: i0,
  type: NgxTrimDirective,
  deps: [{
    token: i0.ElementRef
  }, {
    token: i1.NgControl,
    optional: true
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */ /** @nocollapse */
NgxTrimDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "12.0.0",
  version: "13.2.3",
  type: NgxTrimDirective,
  selector: "input[trim],textarea[trim]",
  inputs: {
    trim: "trim",
    trimOnWriteValue: "trimOnWriteValue"
  },
  host: {
    listeners: {
      "blur": "onBlur($event.target,$event.target.value)",
      "input": "onInput($event.target,$event.target.value)"
    }
  },
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.2.3",
  ngImport: i0,
  type: NgxTrimDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'input[trim],textarea[trim]'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.NgControl,
      decorators: [{
        type: Optional
      }]
    }];
  },
  propDecorators: {
    trim: [{
      type: Input,
      args: ['trim']
    }],
    trimOnWriteValue: [{
      type: Input
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur', ['$event.target', '$event.target.value']]
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event.target', '$event.target.value']]
    }]
  }
});
class NgxTrimDirectiveModule {}
/** @nocollapse */ /** @nocollapse */
NgxTrimDirectiveModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.2.3",
  ngImport: i0,
  type: NgxTrimDirectiveModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
/** @nocollapse */ /** @nocollapse */
NgxTrimDirectiveModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "12.0.0",
  version: "13.2.3",
  ngImport: i0,
  type: NgxTrimDirectiveModule,
  declarations: [NgxTrimDirective],
  exports: [NgxTrimDirective]
});
/** @nocollapse */ /** @nocollapse */
NgxTrimDirectiveModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "13.2.3",
  ngImport: i0,
  type: NgxTrimDirectiveModule,
  imports: [[]]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.2.3",
  ngImport: i0,
  type: NgxTrimDirectiveModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [NgxTrimDirective],
      exports: [NgxTrimDirective]
    }]
  }]
});

/*
 * Public API Surface of ngx-trim-directive
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxTrimDirective, NgxTrimDirectiveModule };
